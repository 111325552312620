.wrapper {
  @apply relative w-full h-[60vh] md:h-[700px] bg-white flex;

  & :global(.loading) {
    @apply absolute inset-0 z-[8] flex items-center justify-center bg-summer-green-23/50;
  }

  & :global(.marker) {
    @apply w-5 h-5 -translate-x-1/2 -translate-y-1/2 rounded-full cursor-pointer bg-black-100 border-2 border-white;
  }

  & :global(.cluster) {
    @apply flex items-center justify-center w-8 h-8 -translate-x-1/2 -translate-y-1/2 rounded-full cursor-pointer bg-black-100 border-2 border-white;

    & span {
      @apply font-sans text-sm font-bold text-white;
    }
  }

  & :global(.controls-wrapper) {
    @apply absolute top-4 right-3 z-10 flex flex-col items-center justify-center w-12;

    & button {
      @apply w-10 h-10 flex items-center justify-center rounded bg-white mb-1 overflow-hidden;

      & svg {
        @apply w-4 h-auto;
      }

      &:global(.recenter) {
        @apply bg-dark-cyan-75;

        & svg {
          @apply w-5 h-auto fill-white;
        }
      }
    }
  }

  & :global(.event-cards-wrapper) {
    @apply absolute bottom-4 left-4 md:bottom-10 md:left-10 z-20 bg-white w-[300px] min-h-[360px];
    @apply rounded-b;
    @apply flex flex-col;

    & :global(.loading) {
      @apply absolute w-full h-full bg-white z-[5];
    }

    & h3 {
      @apply font-sans text-xl font-medium mb-1;
    }

    &:global(.has-error) {
      @apply justify-between items-center p-8;

      & h3 {
        @apply text-black-100/50;
      }
    }

    & :global(.click) {
      @apply absolute w-10 h-10 outline-none -right-5 -top-5;
      @apply z-10 flex items-center justify-center;
    }

    & :global(.close) {
      @apply w-8 h-8 text-white rounded-full cursor-pointer bg-black-100;
      @apply flex items-center justify-center;

      & :global(.icon) {
        @apply fill-white w-[12px] h-auto;
      }
    }

    & a {
      @apply flex flex-col;
    }

    & figure {
      @apply aspect-w-4 aspect-h-3 w-full h-full overflow-hidden;
      @apply bg-black-100/5;
      @apply flex-none;
    }

    & :global(.content) {
      @apply p-4 flex-grow;

      & :global(.tag) {
        @apply text-xs font-bold font-sans tracking-wider uppercase block mb-1;
      }

      & :global(.date) {
        @apply text-sm;
      }
    }

    & nav {
      @apply flex items-center justify-between border-t border-black-100/5;

      & button {
        @apply w-12 h-12 flex items-center justify-center;

        &:disabled svg {
          @apply opacity-30;
        }
      }

      & svg {
        @apply w-5 h-auto pointer-events-none;
      }

      & :global(.prev) {
        @apply rotate-180;
      }
    }
  }
}
