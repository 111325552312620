.wrapper {
  & :global(.result) {
    @apply flex flex-col align-middle justify-center min-h-96 text-center;
    @apply transition-height duration-200;

    & h2 {
      @apply pb-4 text-xl leading-normal;
    }
  }
}
