.card {
  @apply flex mb-8;

  &:hover {
    & img {
      transform: scale(1.025);
    }
    & h3 {
      background-size: 100% 1px;
    }
  }

  & :global(.image-wrapper) {
    @apply w-1/3 aspect-1 max-w-[11rem] relative;

    &:global(.missing-image) {
      @apply bg-black-100/10;
    }
  }

  & :global(.text-wrapper) {
    @apply w-2/3 pl-6 max-w-xl;
  }

  & :global(h3) {
    @apply inline no-underline bg-no-repeat mb-2.5 lg:mb-3;
    @apply font-sans text-xl font-medium;
    @apply pb-0 !important;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-size: 0% 1px;
    transition: background-size 0.3s;
  }

  & :global(img) {
    transition: transform 1s cubic-bezier(0.395, 0.005, 0.19, 1);
  }

  & :global(.member-only-tag) {
    @apply inline-block py-1.5 px-2 mr-3 mb-2 bg-summer-green-23 rounded font-bold uppercase text-xs tracking-[1px];
  }
}
