.wrapper {
  @apply flex flex-col justify-center items-center text-center bg-summer-green-50;
  @apply mb-24 !important;
}

.content {
  @apply min-h-[200px];

  & :global(h1) {
    @apply pt-12 pb-6 text-3xl lg:text-5xl md:text-4xl;
    @apply max-w-2xl mx-auto;
  }

  & :global(.description) {
    @apply px-8 mx-auto text-xl max-w-1xl mb-22;

    & :global(.landing-page-link) {
      @apply inline-block pt-4 relative;
      & a {
        @apply underline;
      }
    }
  }
}

.form {
  @apply box-border flex flex-wrap m-4 mx-auto text-left bg-white p-7 relative;
  @apply shadow-sm text-black-100 p-5 lg:p-7.5 -mb-14 z-10 -mt-13;
  @apply max-w-8xl;
  width: calc(100% - 2rem);

  @screen md {
    width: calc(100% - 4rem);
  }

  & :global(.input-wrapper) {
    @apply flex-1 w-full md:pr-5;

    & :global(.input) {
      @apply flex items-center px-4 mb-5 border rounded md:mb-0 border-black-25 h-15;
    }

    & input {
      @apply flex-1 h-full text-lg focus:outline-none;
    }
  }

  & :global(.select-wrapper) {
    @apply w-full md:w-52;

    & :global(.select) {
      @apply relative block px-0 mx-0 mb-5 font-sans text-lg font-normal text-left border;
      @apply rounded md:mr-5 border-black-25 h-15 md:mb-0;
    }

    & select {
      @apply absolute top-0 block w-full h-full px-5 text-base bg-transparent outline-none;
      @apply appearance-none md:w-44 focus:outline-none;
    }

    & svg {
      @apply absolute w-[11px] h-auto top-0 right-0 mt-6.5 mr-4 pointer-events-none;
    }
  }

  & :global(.button-wrapper) {
    @apply w-full md:w-40;

    & button {
      @apply w-full h-15;
    }
  }
}
