.welcome {
  @apply bg-summer-green-20;

  @apply -mt-20 pt-20;

  & :global(.inner-margin) {
    @apply max-w-8xl mx-auto box-content px-5 sm:px-8 flex flex-col h-full;
  }

  & :global(.illustration-wrapper) {
    & > svg {
      @apply w-full h-auto;
    }

    & :global(.grid-wrapper) {
      @apply bg-summer-green-23;
    }
  }

  & :global(.header) {
    @apply flex-grow flex flex-col justify-center items-center text-center;

    & h1 {
      @apply leading-10 mb-2 md:mb-4 lg:pt-7 lg:pb-4;
    }

    & p {
      @apply font-serif text-xl lg:text-2xl mb-6 max-w-sm md:max-w-[660px] lg:pb-5;
    }
  }

  & :global(.icon-card-grid) {
    @apply pt-4 md:pt-10 pb-10 md:pb-20 grid gap-x-10 gap-y-6 self-end;
    @apply bg-summer-green-23;

    &:global(.cards-1) {
      @apply grid-cols-1;
    }

    &:global(.cards-2) {
      @apply grid-cols-1 md:grid-cols-2;
    }

    &:global(.cards-3) {
      @apply grid-cols-1 md:grid-cols-2 lg:grid-cols-3;
    }

    &:global(.cards-4) {
      @apply grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
    }
  }
}
