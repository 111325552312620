.wrapper {
  @apply z-20 relative pb-8;

  &:global(.has-image header .breadcrumbs),
  &:global(.has-image header .content) {
    @apply text-white;
  }

  & > header {
    @apply relative flex flex-col items-center text-center;

    & figure img {
      filter: brightness(70%);
    }

    & :global(.content) {
      @apply pt-10 pb-24 z-10;

      & :global(span) {
        @apply text-sm font-bold font-sans tracking-wider uppercase block;
      }

      & :global(h1) {
      }
    }
  }

  & :global(.form-wrapper) {
    @apply box-content px-4 md:px-8 -mt-13 relative z-10;
    @apply max-w-8xl mx-auto;
    @apply w-[calc(100%-2rem)] md:w-[calc(100%-4rem)];

    & :global(.search-wrapper) {
      @apply bg-white shadow p-6 mb-8 lg:flex lg:flex-wrap;

      & :global(.search) {
        @apply mb-4 lg:mb-0 lg:max-w-[340px] lg:mr-4;
      }

      & :global(.filters) {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4;
        @apply flex-grow;

        & :global(.is-full) {
          @apply md:col-span-2 lg:col-span-1;
        }
      }

      & :global(.choices) {
        @apply w-full mt-4;

        & ul {
          @apply mt-2;
        }

        & li {
          @apply inline-block mr-2 mb-2 bg-summer-green-25 rounded;
          @apply text-xs font-sans font-bold tracking-widest uppercase px-2 py-1;
          @apply cursor-pointer inline-flex justify-between items-center;

          & svg {
            @apply inline-block w-[8px] h-[8px] ml-1;
          }

          &:global(.clear-all) {
            @apply bg-transparent;
          }
        }
      }
    }
  }

  & :global(.sub-wrapper) {
    @apply md:flex md:justify-between md:items-center;

    & h2 {
      @apply pb-4 md:pb-0 text-xl;
    }

    & :global(.settings-wrapper) {
      @apply grid grid-cols-2 gap-4;
      @apply md:flex md:justify-end md:space-x-4 md:gap-0;
    }

    & :global(.settings-wrapper) {
      @apply md:flex-grow;

      &:global(.is-map-view .sort) {
        @apply hidden;
      }

      &:global(.is-map-view .view) {
        @apply col-span-2;
      }

      & > div {
        @apply md:max-w-[168px];
      }
    }
  }
}
